// components/Sidebar.js
import React from 'react';
import { 
  FaHome, 
  FaCartPlus, 
  FaBell, 
  FaClock, 
  FaStore, 
  FaCog, 
  FaSignOutAlt,
  FaBars,
  FaSun,
  FaMoon
} from 'react-icons/fa';

export const Sidebar = ({
  selectedOption,
  setSelectedOption,
  darkMode,
  toggleDarkMode,
  sidebarCollapsed,
  toggleSidebar,
  handleLogout,
  userId
}) => {
  const menuItems = [
    { id: 'dashboard', icon: FaHome, text: 'Dashboard' },
    { id: 'a2aDatabase', icon: FaCartPlus, text: 'A2A Database' },
    { id: 'a2aNotifications', icon: FaBell, text: 'A2A Notifications' },
    { id: 'asinMonitor', icon: FaClock, text: 'ASIN Monitor' },
    { id: 'storefrontHunter', icon: FaStore, text: 'Storefront Hunter' },
    { id: 'settings', icon: FaCog, text: 'Settings' }
  ];

  return (
    <div className={`sidebar ${sidebarCollapsed ? 'collapsed' : ''}`}>
      <button className="sidebar-toggle" onClick={toggleSidebar}>
        <FaBars />
      </button>
      
      {!sidebarCollapsed && (
        <div className="sidebar-header flex items-center gap-2">
          <img 
            src="/Logo11.png" 
            alt="Profit Hunter Logo" 
            className="w-8 h-8"
          />
          <h1 className="sidebar-title text-lg">Profit Hunter</h1>
        </div>
      )}

      <ul className="sidebar-menu">
        {menuItems.map(({ id, icon: Icon, text }) => (
          <li 
            key={id}
            className={`sidebar-menu-item ${selectedOption === id ? 'active bg-[#8957e5] text-white' : ''}`}
            onClick={() => setSelectedOption(id)}
          >
            <Icon className="sidebar-icon" />
            {!sidebarCollapsed && <span className="sidebar-text">{text}</span>}
          </li>
        ))}
        <li className="sidebar-menu-item" onClick={handleLogout}>
          <FaSignOutAlt className="sidebar-icon" />
          {!sidebarCollapsed && <span className="sidebar-text">Logout</span>}
        </li>
      </ul>

      <div className="mt-auto pt-4 pb-2 px-4 text-black flex items-center">
        <span className="sidebar-icon text-base">User ID:</span>
        {!sidebarCollapsed && (
          <span className="sidebar-text text-xl ml-1 font-semibold">
            {userId || 'Loading...'}
          </span>
        )}
      </div>
    </div>
  );
};
