// src/components/DatabaseView.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { 
  FaChartLine, 
  FaShoppingCart, 
  FaSort, 
  FaSortUp, 
  FaSortDown, 
  FaPlus,
  FaSpinner,
  FaSearch
} from 'react-icons/fa';
import PurchaseModal from '../PurchaseModal';

const API_BASE_URL = 'https://profithunter.app/api';

export const DatabaseView = ({ userId, setTotalResults }) => {
  // State management
  const [databaseResults, setDatabaseResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [resultsPerPage] = useState(100);
  const [allResults, setAllResults] = useState([]);
  const [filteredResults, setFilteredResults] = useState([]);
  const [showPriceModal, setShowPriceModal] = useState(false);
  const [selectedAsin, setSelectedAsin] = useState(null);
  const [filterOptions, setFilterOptions] = useState({
    category: '',
    minProfit: '',
    minROI: '',
    minMonthlySales: '',
    maxASINRank: '',
  });
  const [categories, setCategories] = useState([]);
  const [searchTitle, setSearchTitle] = useState('');
  const [sortField, setSortField] = useState('timestamp');
  const [sortDirection, setSortDirection] = useState('desc');
  const [totalResults, setLocalTotalResults] = useState(0);
  const [showPurchaseModal, setShowPurchaseModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const sourceOptions = [
    'Amazon UK',
    'Amazon DE',
    'Amazon FR',
    'Amazon IT',
    'Amazon ES',
    'Amazon US',
    'No Rank DE',
    'No Rank ES',
    'No Rank FR',
    'No Rank DE',
    'No Rank UK',
    'No Rank IT'
  ];

  // Currency mapping
  const CURRENCY_MAPPING = {
    'Amazon UK': { symbol: '£', code: 'GBP' },
    'Amazon DE': { symbol: '€', code: 'EUR' },
    'Amazon FR': { symbol: '€', code: 'EUR' },
    'Amazon IT': { symbol: '€', code: 'EUR' },
    'Amazon ES': { symbol: '€', code: 'EUR' },
    'Amazon US': { symbol: '$', code: 'USD' }
  };

  // Utility functions
  const formatPrice = (price, source = 'Amazon UK') => {
    const currencyInfo = CURRENCY_MAPPING[source] || CURRENCY_MAPPING['Amazon UK'];
    return `${currencyInfo.symbol}${price?.toFixed(2) || '0.00'}`;
  };

  const getKeepaMarket = (source) => {
    const marketMap = {
      'Amazon DE': '1',
      'Amazon FR': '3',
      'Amazon IT': '4',
      'Amazon ES': '5',
      'Amazon US': '1',
      'Amazon UK': '2'
    };
    return marketMap[source] || '2';
  };

  const getAmazonDomain = (source) => {
    const domainMap = {
      'Amazon DE': 'amazon.de',
      'Amazon FR': 'amazon.fr',
      'Amazon IT': 'amazon.it',
      'Amazon ES': 'amazon.es',
      'Amazon US': 'amazon.com',
      'Amazon UK': 'amazon.co.uk'
    };
    return domainMap[source] || 'amazon.co.uk';
  };

  const fetchInitialData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${API_BASE_URL}/results`, {
        withCredentials: true
      });
      
      // The response is directly an array of results
      if (Array.isArray(response.data)) {
        setAllResults(response.data);
        setFilteredResults(response.data);
        setLocalTotalResults(response.data.length);
        setTotalResults(response.data.length);
      } else {
        console.error('Unexpected response format:', response.data);
        setError('Received invalid data format from server');
      }
    } catch (error) {
      console.error('Error fetching initial data:', error);
      setError('Failed to fetch results. Please try again.');
    } finally {
      setLoading(false);
    }
  };

// Add this useEffect hook after your fetchInitialData function definition
useEffect(() => {
    fetchInitialData();
    fetchCategories();
}, []); // Empty dependency array means this runs once when component mounts

  useEffect(() => {
    if (!allResults.length) return;

    // Apply filters to all results first
    let filtered = [...allResults];

    // Apply filters
    if (filterOptions.category) {
      filtered = filtered.filter(item => item.category === filterOptions.category);
    }
    if (filterOptions.source) {
      filtered = filtered.filter(item => item.source === filterOptions.source);
    }
    if (filterOptions.minProfit) {
      filtered = filtered.filter(item => item.profit >= parseFloat(filterOptions.minProfit));
    }
    if (filterOptions.minROI) {
      filtered = filtered.filter(item => item.roi_percentage >= parseFloat(filterOptions.minROI));
    }
    if (filterOptions.minMonthlySales) {
      filtered = filtered.filter(item => item.monthly_sales >= parseInt(filterOptions.minMonthlySales));
    }
    if (filterOptions.maxASINRank) {
      filtered = filtered.filter(item => item.asin_rank <= parseInt(filterOptions.maxASINRank));
    }
    if (searchTitle) {
      const searchLower = searchTitle.toLowerCase();
      filtered = filtered.filter(item => item.title.toLowerCase().includes(searchLower));
    }

    // Apply sorting
    if (sortField) {
      filtered.sort((a, b) => {
        let aVal = a[sortField];
        let bVal = b[sortField];
        
        if (sortField === 'timestamp') {
          aVal = new Date(aVal).getTime();
          bVal = new Date(bVal).getTime();
        }
        
        if (sortDirection === 'asc') {
          return aVal > bVal ? 1 : -1;
        } else {
          return aVal < bVal ? 1 : -1;
        }
      });
    }

    // Set filtered results and update total count
    setFilteredResults(filtered);
    setLocalTotalResults(filtered.length);
    setTotalResults(filtered.length);
    
    // Reset to first page when filters change
    setCurrentPage(1);
  }, [allResults, filterOptions, searchTitle, sortField, sortDirection]);

  // Handlers
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilterOptions(prev => ({
      ...prev,
      [name]: value
    }));
    setCurrentPage(1);
  };

  const handleSearchChange = (e) => {
    setSearchTitle(e.target.value);
    setCurrentPage(1);
  };

  const handleSort = (field) => {
    if (field === sortField) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('desc');
    }
    setCurrentPage(1);
  };

  const handleRowClick = (product) => {
    setSelectedProduct(product);
    setShowPurchaseModal(true);
  };

  const handleAddPurchase = async (purchaseData) => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/purchases`,
        purchaseData,
        { withCredentials: true }
      );
      console.log('Purchase added:', response.data);
    } catch (error) {
      console.error('Error adding purchase:', error);
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/categories`);
      setCategories(response.data);
    } catch (error) {
      console.error('Error fetching categories:', error);
      setError('Failed to fetch categories. Please try again.');
    }
  };

  const renderSortIcon = (field) => {
    if (sortField === field) {
      return sortDirection === 'asc' ? <FaSortUp /> : <FaSortDown />;
    }
    return <FaSort />;
  };

  const indexOfLastResult = currentPage * resultsPerPage;
  const indexOfFirstResult = indexOfLastResult - resultsPerPage;
  const currentResults = filteredResults.slice(indexOfFirstResult, indexOfLastResult);

  // Price Input Modal Component
  const PriceInputModal = ({ isOpen, onClose, onSubmit, asin }) => {
    const [desiredPrice, setDesiredPrice] = useState('');
  
    const handleSubmit = (e) => {
      e.preventDefault();
      const parsedPrice = parseFloat(desiredPrice);
      
      if (isNaN(parsedPrice) || parsedPrice <= 0) {
        alert('Please enter a valid price');
        return;
      }
  
      onSubmit(asin, parsedPrice);
      setDesiredPrice('');
      onClose();
    };
  
    if (!isOpen) return null;
  
    return (
      <div className="modal">
        <div className="modal-content">
          <button className="close-button" onClick={onClose}>×</button>
          <h3>Add Price Monitor for {asin}</h3>
          <form className="notification-form" onSubmit={handleSubmit}>
            <input
              type="number"
              step="0.01"
              placeholder="Enter desired price"
              value={desiredPrice}
              onChange={(e) => setDesiredPrice(e.target.value)}
              min="0.01"
              required
            />
            <button type="submit">Add Monitor</button>
          </form>
        </div>
      </div>
    );
  };

  const handleAddMonitor = async (asin, targetPrice) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/price-monitor/tasks`, {
        asin,
        target_price: targetPrice,
      }, {
        withCredentials: true
      });
      console.log('Monitor added successfully:', response.data);
      alert('Price monitor added successfully!');
    } catch (error) {
      console.error('Error adding price monitor:', error);
      alert(`Failed to add price monitor: ${error.response?.data?.error || error.message}`);
    }
  };

  // First, add the KeepaTooltip component after the existing utility functions (getKeepaMarket, getAmazonDomain, etc.)
  // Around line 35, after the utility functions:

  const KeepaTooltip = ({ asin, source }) => {
    const market = getKeepaMarket(source);
    return (
      <div className="fixed z-50 bg-white p-2 rounded-lg shadow-xl border border-gray-200 hidden group-hover:block" 
        style={{ 
          left: '20px',  // Fixed distance from left
          top: '50%',    // Center vertically
          transform: 'translateY(-50%)'  // Perfect vertical centering
        }}>
        <img 
          src={`https://graph.keepa.com/pricehistory.png?asin=${asin}&domain=2&width=600&height=250`} 
          alt="Keepa Price History"
          className="max-w-[600px]"
          loading="lazy"
        />
      </div>
    );
  };

  // Add this function near the top with other utility functions
  const autoSearchPrices = (result) => {
    // Array of search URLs
    const searchUrls = [
      // Google Shopping search
      `https://www.google.com/search?tbm=shop&q=${encodeURIComponent(`${result.title}`)}`,
      // eBay search
      `https://www.ebay.co.uk/sch/i.html?_nkw=${encodeURIComponent(`${result.title}`)}`,
      // Google Lens search
      `https://lens.google.com/search?p=${encodeURIComponent(`${result.title} £${result.current_price} UK`)}`
    ];

    // Open each search in a new tab
    searchUrls.forEach(url => window.open(url, '_blank'));
  };

  return (
    <div className="a2a-notifications">
      <div className="filter-bar w-full flex flex-wrap gap-4 mb-4 px-4">
        <select 
          name="category" 
          value={filterOptions.category} 
          onChange={handleFilterChange}
          className="flex-1 min-w-[150px] p-2 border rounded"
        >
          <option value="">All Categories</option>
          {categories.map(category => (
            <option key={category} value={category}>{category}</option>
          ))}
        </select>
        <select 
          name="source" 
          value={filterOptions.source || ''} 
          onChange={handleFilterChange}
          className="flex-1 min-w-[150px] p-2 border rounded"
        >
          <option value="">All Sources</option>
          {sourceOptions.map(source => (
            <option key={source} value={source}>
              {source}
            </option>
          ))}
        </select>
        <input 
          type="number" 
          name="minProfit" 
          placeholder="Min Profit" 
          value={filterOptions.minProfit} 
          onChange={handleFilterChange}
          className="flex-1 min-w-[150px] p-2 border rounded"
        />
        <input 
          type="number" 
          name="minROI" 
          placeholder="Min ROI %" 
          value={filterOptions.minROI} 
          onChange={handleFilterChange}
          className="flex-1 min-w-[150px] p-2 border rounded"
        />
        <input 
          type="number" 
          name="minMonthlySales" 
          placeholder="Min Monthly Sales" 
          value={filterOptions.minMonthlySales} 
          onChange={handleFilterChange}
          className="flex-1 min-w-[150px] p-2 border rounded"
        />
        <input 
          type="number" 
          name="maxASINRank" 
          placeholder="Max ASIN Rank" 
          value={filterOptions.maxASINRank} 
          onChange={handleFilterChange}
          className="flex-1 min-w-[150px] p-2 border rounded"
        />
        <input 
          type="text" 
          placeholder="Search by title" 
          value={searchTitle} 
          onChange={handleSearchChange}
          className="flex-1 min-w-[200px] p-2 border rounded"
        />
      </div>

      {loading ? (
        <div className="flex items-center justify-center p-12">
          <FaSpinner className="animate-spin text-4xl text-purple-600" />
        </div>
      ) : error ? (
        <p className="error">{error}</p>
      ) : (
        <div className="notifications-container">
          <table className="w-full border-collapse">
            <thead>
              <tr className="bg-white border-b border-gray-200">
                <th className="px-6 py-4 text-left text-xs font-bold text-gray-800 uppercase tracking-wider whitespace-nowrap">
                  Time<br/>stamp
                </th>
                <th className="px-6 py-4 text-left text-xs font-bold text-gray-800 uppercase tracking-wider">
                  Image
                </th>
                <th className="px-6 py-4 text-left text-xs font-bold text-gray-800 uppercase tracking-wider">
                  Title
                </th>
                <th className="px-6 py-4 text-left text-xs font-bold text-gray-800 uppercase tracking-wider">
                  ASIN
                </th>
                <th className="px-6 py-4 text-left text-xs font-bold text-gray-800 uppercase tracking-wider">
                  Current<br/>Price
                </th>
                <th className="px-6 py-4 text-left text-xs font-bold text-gray-800 uppercase tracking-wider">
                  Avg 90<br/>Day Price
                </th>
                <th className="px-6 py-4 text-left text-xs font-bold text-gray-800 uppercase tracking-wider">
                  Profit
                </th>
                <th className="px-6 py-4 text-left text-xs font-bold text-gray-800 uppercase tracking-wider">
                  ROI<br/>%
                </th>
                <th className="px-6 py-4 text-left text-xs font-bold text-gray-800 uppercase tracking-wider">
                  Monthly<br/>Sales
                </th>
                <th className="px-6 py-4 text-left text-xs font-bold text-gray-800 uppercase tracking-wider">
                  Category
                </th>
                <th className="px-6 py-4 text-left text-xs font-bold text-gray-800 uppercase tracking-wider">
                  ASIN<br/>Rank
                </th>
                <th className="px-6 py-4 text-left text-xs font-bold text-gray-800 uppercase tracking-wider">
                  Offer<br/>Count
                </th>
                <th className="px-6 py-4 text-left text-xs font-bold text-gray-800 uppercase tracking-wider">
                  Source
                </th>
                <th className="px-6 py-4 text-left text-xs font-bold text-gray-800 uppercase tracking-wider">
                  Keepa<br/>Drops
                </th>
                <th className="px-6 py-4 text-left text-xs font-bold text-gray-800 uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {currentResults.map((result, index) => (
                <tr 
                  key={index}
                  onClick={() => handleRowClick({
                    asin: result.asin,
                    title: result.title,
                    current_price: result.current_price,
                    avg_90day_price: result.avg_90day_price,
                    profit: result.profit,
                    roi_percentage: result.roi_percentage,
                    monthly_sales: result.monthly_sales,
                    category: result.category,
                    asin_rank: result.asin_rank,
                    offer_count: result.offer_count,
                    source: result.source,
                    keepa_drops: result.keepa_drops,
                    image_url: result.image_url?.split(',')[0]
                  })}
                  className="hover:bg-gray-50 transition-colors duration-200"
                >
                  <td className="px-6 py-4 whitespace-nowrap w-[10%]">
                    {new Date(result.timestamp).toLocaleString('en-GB', {
                      day: '2-digit',
                      month: '2-digit',
                      year: 'numeric',
                      hour: '2-digit',
                      minute: '2-digit',
                      second: '2-digit',
                      hour12: false
                    })}
                  </td>
                  <td className="px-6 py-4 w-[5%]">
                    <img 
                      src={`https://images-na.ssl-images-amazon.com/images/I/${result.image_url.split(',')[0]}`} 
                      alt={result.title}
                      className="h-12 w-12 object-contain"
                    />
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900 w-[25%]">{result.title}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 w-[8%]">{result.asin}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 w-[7%]">
                    {formatPrice(result.current_price, result.source)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 w-[7%]">
                    £{result.avg_90day_price?.toFixed(2) || '0.00'}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 w-[6%]">
                    £{result.profit?.toFixed(2) || '0.00'}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 w-[6%]">
                    {result.roi_percentage.toFixed(2)}%
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 w-[6%]">
                    {result.monthly_sales === null ? '0-49' : result.monthly_sales}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 w-[8%]">
                    {result.category}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 w-[6%]">
                    {result.asin_rank}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 w-[3%]">
                    {result.offer_count}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 w-[6%]">
                    {result.source}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 w-[4%]">
                    {result.keepa_drops || '0'}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap w-[5%]" onClick={(e) => e.stopPropagation()}>
                    <div className="flex items-center gap-3">
                      <a 
                        href={`https://sas.selleramp.com/sas/lookup?asin=${result.asin}&sas_cost_price=${result.current_price}&sas_sale_price=${result.avg_90day_price}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex items-center justify-center w-4 h-4 rounded-full bg-blue-500 text-white text-xs font-bold hover:bg-blue-600"
                        title="Analyze with SAS"
                      >
                        S
                      </a>
                      <a 
                        href={`https://keepa.com/#!product/${getKeepaMarket(result.source)}-${result.asin}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-gray-600 hover:text-gray-900 relative group"
                        title="View on Keepa"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <FaChartLine className="h-4 w-4" />
                        <KeepaTooltip 
                          asin={result.asin}
                          source={result.source}
                        />
                      </a>
                      <a 
                        href={`https://${getAmazonDomain(result.source)}/dp/${result.asin}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-gray-600 hover:text-gray-900"
                        title="View on Amazon"
                      >
                        <FaShoppingCart className="h-4 w-4" />
                      </a>
                      <button
                        onClick={() => {
                          setShowPriceModal(true);
                          setSelectedAsin(result.asin);
                        }}
                        className="text-gray-600 hover:text-gray-900"
                        title="Add to Price Monitor"
                      >
                        <FaPlus className="h-4 w-4" />
                      </button>
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          autoSearchPrices(result);
                        }}
                        className="text-gray-600 hover:text-gray-900"
                        title="Auto Search Prices"
                      >
                        <FaSearch className="h-4 w-4" />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {/* Pagination Controls */}
      <div className="flex items-center justify-between px-4 py-3 bg-white border-t border-gray-200 sm:px-6">
        <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
          <div>
            <p className="text-sm text-gray-700">
              Showing{' '}
              <span className="font-medium">
                {Math.min((currentPage - 1) * resultsPerPage + 1, totalResults)}
              </span>
              {' '}-{' '}
              <span className="font-medium">
                {Math.min(currentPage * resultsPerPage, totalResults)}
              </span>
              {' '}of{' '}
              <span className="font-medium">{totalResults}</span>
              {' '}results
            </p>
          </div>
          <div>
            <nav className="inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
              <button
                onClick={() => setCurrentPage(1)}
                disabled={currentPage === 1}
                className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-50"
              >
                First
              </button>
              <button
                onClick={() => setCurrentPage(prev => Math.max(1, prev - 1))}
                disabled={currentPage === 1}
                className="relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-50"
              >
                Previous
              </button>
              <span className="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700">
                Page {currentPage} of {Math.ceil(totalResults / resultsPerPage)}
              </span>
              <button
                onClick={() => setCurrentPage(prev => Math.min(Math.ceil(totalResults / resultsPerPage), prev + 1))}
                disabled={currentPage === Math.ceil(totalResults / resultsPerPage)}
                className="relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-50"
              >
                Next
              </button>
              <button
                onClick={() => setCurrentPage(Math.ceil(totalResults / resultsPerPage))}
                disabled={currentPage === Math.ceil(totalResults / resultsPerPage)}
                className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-50"
              >
                Last
              </button>
            </nav>
          </div>
        </div>
      </div>

      {/* Modals */}
      {showPriceModal && (
        <PriceInputModal
          isOpen={showPriceModal}
          onClose={() => setShowPriceModal(false)}
          onSubmit={handleAddMonitor}
          asin={selectedAsin}
        />
      )}

      {showPurchaseModal && (
        <PurchaseModal
          isOpen={showPurchaseModal}
          onClose={() => setShowPurchaseModal(false)}
          product={selectedProduct}
          onSubmit={handleAddPurchase}
        />
      )}
    </div>
  );
};

export default DatabaseView;
