import React, { useState, useEffect } from 'react';
import { Route, Routes, Navigate, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import './App.css';
import { jwtDecode } from 'jwt-decode';
import Cookies from 'js-cookie';
import { Sidebar } from './components/Sidebar';
import { DatabaseView } from './components/DatabaseView';
import { NotificationsView } from './components/NotificationsView';
import { MainContent } from './components/MainContent';
import CookieConsent from './components/CookieConsent';
import Login from './Login';
import Dashboard from './Dashboard';
import Settings from './Settings';
import PriceMonitor from './PriceMonitor';
import StorefrontHunter from './StorefrontHunter';
import ProfitHunterLanding from './ProfitHunterLanding.jsx';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const API_BASE_URL = 'https://profithunter.app/api';
axios.defaults.withCredentials = true;

// Set up axios interceptor
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401 && error.response?.data?.redirect) {
      // Clear auth state
      Cookies.remove('jwt_token');
      localStorage.clear();
      
      // Show message to user
      toast.info('Your session has expired. Please log in again.');
      
      // Redirect to login
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

function App() {
  const [selectedOption, setSelectedOption] = useState('dashboard');
  const [darkMode, setDarkMode] = useState(() => {
    const storedPreference = localStorage.getItem('darkMode');
    return storedPreference ? JSON.parse(storedPreference) : false;
  });
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [userId, setUserId] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/auth/user`, {
          withCredentials: true
        });
        if (response.data && !response.data.error) {
          setIsAuthenticated(true);
          setUserId(response.data.user_id);
        }
      } catch (error) {
        console.error('Auth check failed:', error);
        setIsAuthenticated(false);
      }
      setIsLoading(false);
    };
    checkAuth();
  }, []);

  useEffect(() => {
    localStorage.setItem('darkMode', JSON.stringify(darkMode));
  }, [darkMode]);

  const handleLogout = () => {
    setIsAuthenticated(false);
    Cookies.remove('jwt_token');
    navigate('/');
  };

  const toggleDarkMode = () => setDarkMode(!darkMode);
  const toggleSidebar = () => setSidebarCollapsed(!sidebarCollapsed);

  const PrivateRoute = ({ children }) => {
    if (isLoading) return <div>Loading...</div>;
    return isAuthenticated ? children : <Navigate to="/login" state={{ from: location }} />;
  };

  return (
    <div className={`app ${darkMode ? 'dark' : 'light'}`}>
      <ToastContainer /> {/* Add ToastContainer */}
      <Routes>
        {/* Landing page as the root route */}
        <Route path="/" element={
          isAuthenticated ? <Navigate to="/dashboard" /> : <ProfitHunterLanding />
        } />
        
        {/* Login route */}
        <Route path="/login" element={
          isAuthenticated ? <Navigate to="/dashboard" /> : <Login />
        } />
        
        {/* Protected dashboard route */}
        <Route path="/dashboard" element={
          <PrivateRoute>
            <div className="app-container">
              <Sidebar 
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
                darkMode={darkMode}
                toggleDarkMode={toggleDarkMode}
                sidebarCollapsed={sidebarCollapsed}
                toggleSidebar={toggleSidebar}
                handleLogout={handleLogout}
                userId={userId}
              />
              <MainContent 
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
                userId={userId}
                darkMode={darkMode}
              />
            </div>
          </PrivateRoute>
        } />
        {/* Catch all route - redirect to landing page */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      <CookieConsent />
    </div>
  );
}

export default App;
