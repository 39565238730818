// components/MainContent.js
import React, { useState } from 'react';
import { DatabaseView } from './DatabaseView';
import { NotificationsView } from './NotificationsView';
import Dashboard from '../Dashboard';
import PriceMonitor from '../PriceMonitor';
import StorefrontHunter from '../StorefrontHunter';
import Settings from '../Settings';

export const MainContent = ({ selectedOption, setSelectedOption, userId, darkMode }) => {
  const [totalResults, setTotalResults] = useState(0);
  const [notificationCount, setNotificationCount] = useState(0);
  const [activeMonitors, setActiveMonitors] = useState(0);

  const renderContent = () => {
    switch (selectedOption) {
      case 'dashboard':
        return (
          <Dashboard 
            totalResults={totalResults}
            setTotalResults={setTotalResults}
            notificationCount={notificationCount}
            setNotificationCount={setNotificationCount}
            activeMonitors={activeMonitors}
            setActiveMonitors={setActiveMonitors}
            setSelectedOption={setSelectedOption}
          />
        );
      case 'a2aDatabase':
        return <DatabaseView userId={userId} setTotalResults={setTotalResults} />;
      case 'a2aNotifications':
        return <NotificationsView userId={userId} setNotificationCount={setNotificationCount} />;
      case 'asinMonitor':
        return <PriceMonitor userId={userId} setActiveMonitors={setActiveMonitors} />;
      case 'storefrontHunter':
        return <StorefrontHunter userId={userId} />;
      case 'settings':
        return <Settings darkMode={darkMode} />;
      default:
        return <div>Please select an option</div>;
    }
  };

  return (
    <div className="flex-1 p-8 ml-64">
      <div className="max-w-[1920px] mx-auto">
        {renderContent()}
      </div>
    </div>
  );
};
