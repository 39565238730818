import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaSearch, FaSpinner, FaExternalLinkAlt, FaPlus, FaTasks, FaTimes, FaPound, FaTrash } from 'react-icons/fa';

const API_BASE_URL = 'https://profithunter.app/api';

const ProductCard = ({ product }) => {
  const [showZoomModal, setShowZoomModal] = useState(false);

  const getAmazonImageUrl = (imageKey) => {
    if (!imageKey) return null;
    return `https://images-na.ssl-images-amazon.com/images/I/${imageKey}`;
  };

  const getLinks = (asin, currentPrice, avgPrice) => {
    return {
      sas: `https://sas.selleramp.com/sas/lookup?asin=${asin}&sas_cost_price=${currentPrice || 0}&sas_sale_price=${avgPrice || 0}`,
      keepa: `https://keepa.com/#!product/2-${asin}`,
      amazon: `https://www.amazon.co.uk/dp/${asin}`
    };
  };

  const links = getLinks(
    product.asin, 
    product.current_price_amazon, 
    product.avg_90day_price_amazon
  );

  const imageUrl = getAmazonImageUrl(product.image_url);

  return (
    <div className="border rounded-lg p-4 shadow bg-white hover:shadow-lg transition-shadow">
      <div className="flex gap-4">
        {product.image_url ? (
          <div>
            <img 
              src={imageUrl}
              alt={product.title} 
              className="w-24 h-24 object-contain bg-gray-50 rounded"
            />
          </div>
        ) : (
          <div className="w-24 h-24 bg-gray-100 rounded flex items-center justify-center">
            No Image
          </div>
        )}
        <div className="flex-1">
          <h3 className="font-medium text-sm mb-2 line-clamp-2">{product.title}</h3>
          <div className="grid grid-cols-2 gap-2 text-sm">
            <div className="text-gray-600">ASIN: <span className="text-black">{product.asin}</span></div>
            <div className="text-gray-600">Category: <span className="text-black">{product.category || 'N/A'}</span></div>
          </div>
        </div>
      </div>

      <div className="mt-4 grid grid-cols-2 gap-4 text-sm">
        <div className="border-r">
          <h4 className="font-medium mb-2">Amazon Prices</h4>
          <div className="space-y-1">
            <div>Current: {product.current_price_amazon ? 
              <span className="text-green-600">£{product.current_price_amazon.toFixed(2)}</span> : 
              <span className="text-gray-500">N/A</span>
            }</div>
            <div>90-Day Avg: {product.avg_90day_price_amazon ? 
              <span>£{product.avg_90day_price_amazon.toFixed(2)}</span> : 
              <span className="text-gray-500">N/A</span>
            }</div>
          </div>
        </div>

        <div>
          <h4 className="font-medium mb-2">BB Prices</h4>
          <div className="space-y-1">
            <div>Current: {product.current_price_bb ? 
              <span className="text-green-600">£{product.current_price_bb.toFixed(2)}</span> : 
              <span className="text-gray-500">N/A</span>
            }</div>
            <div>90-Day Avg: {product.avg_90day_price_bb ? 
              <span>£{product.avg_90day_price_bb.toFixed(2)}</span> : 
              <span className="text-gray-500">N/A</span>
            }</div>
          </div>
        </div>
      </div>

      <div className="mt-4 grid grid-cols-2 gap-4 text-sm border-t pt-4">
        <div>
          <h4 className="font-medium mb-2">Sales Data</h4>
          <div className="space-y-1">
            <div>Monthly Sales: {product.monthly_sales || 'N/A'}</div>
            <div>Rank Drops (30d): {product.sales_rank_drops_30 || 'N/A'}</div>
          </div>
        </div>
        <div>
          <h4 className="font-medium mb-2">Rankings</h4>
          <div className="space-y-1">
            <div>ASIN Rank: {product.asin_rank || 'N/A'}</div>
            <div>Store: {product.store_name || 'N/A'}</div>
          </div>
        </div>
      </div>

      {/* Links section */}
      <div className="mt-4 border-t pt-4">
        <h4 className="font-medium mb-2 text-sm">Quick Links</h4>
        <div className="flex gap-3 text-sm">
          <a
            href={links.sas}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 hover:text-blue-800 flex items-center gap-1"
            title="Check SAS Analysis"
          >
            <FaExternalLinkAlt className="w-3 h-3" />
            <span>SAS</span>
          </a>
          <span className="text-gray-300">|</span>
          <a
            href={links.keepa}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 hover:text-blue-800 flex items-center gap-1"
            title="View Keepa Price History"
          >
            <FaExternalLinkAlt className="w-3 h-3" />
            <span>Keepa</span>
          </a>
          <span className="text-gray-300">|</span>
          <a
            href={links.amazon}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 hover:text-blue-800 flex items-center gap-1"
            title="View on Amazon"
          >
            <FaExternalLinkAlt className="w-3 h-3" />
            <span>Amazon</span>
          </a>
        </div>
      </div>

      <div className="mt-4 text-xs text-gray-500">
        Last Updated: {formatDate(product.timestamp)}
      </div>

      {/* Image Zoom Modal */}
      {showZoomModal && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4"
          onClick={() => setShowZoomModal(false)}
        >
          <div className="relative max-w-3xl max-h-[90vh] bg-white rounded-lg p-4">
            <button 
              onClick={() => setShowZoomModal(false)}
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 z-10"
            >
              <FaTimes size={24} />
            </button>
            <img 
              src={imageUrl}
              alt={product.title}
              className="max-h-[80vh] w-auto object-contain mx-auto"
              onClick={(e) => e.stopPropagation()}
            />
            <p className="mt-2 text-sm text-gray-600 text-center">{product.title}</p>
          </div>
        </div>
      )}
    </div>
  );
};

const AddTaskModal = ({ onClose, onSubmit }) => {
  const [taskData, setTaskData] = useState({
    storefront_id: ''
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!taskData.storefront_id.trim()) {
      alert('Please enter a Storefront ID');
      return;
    }
    onSubmit(taskData);
  };


  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg w-full max-w-md">
        <div className="p-6">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-2xl font-bold">Add Storefront Monitor</h2>
            <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
              <FaTimes />
            </button>
          </div>
          
          <div className="bg-gray-100 rounded-lg p-4 mb-6">
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Storefront ID
                </label>
                <input
                  type="text"
                  value={taskData.storefront_id}
                  onChange={(e) => setTaskData(prev => ({ ...prev, storefront_id: e.target.value }))}
                  placeholder="Enter Storefront ID"
                  className="w-full p-2 border rounded-md focus:ring-2 focus:ring-purple-500 focus:border-purple-500"
                />
                <p className="mt-1 text-xs text-gray-500">
                  Enter the Amazon Storefront ID you want to monitor
                </p>
              </div>
            </div>
          </div>

          <div className="border-l-4 border-purple-500 bg-purple-50 p-4 mb-6">
            <h4 className="text-purple-600 font-medium mb-2">Monitor Details</h4>
            <div className="space-y-2 text-sm text-gray-600">
              <p>• Monitors will automatically scan the storefront for new products</p>
              <p>• Updates are processed every 4 hours</p>
              <p>• You can view and manage your monitors in the Tasks view</p>
            </div>
          </div>

          <div className="flex justify-end gap-2">
            <button
              onClick={onClose}
              className="px-4 py-2 text-gray-600 bg-gray-200 rounded hover:bg-gray-300"
            >
              Cancel
            </button>
            <button
              onClick={handleSubmit}
              className="inline-flex items-center gap-2 px-4 py-2 bg-[#8957e5] text-white rounded-md hover:bg-[#7548d5] transition-colors"
            >
              Start Monitoring
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const TasksModal = ({ onClose }) => {
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [storeNames, setStoreNames] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [tasksResponse, summaryResponse] = await Promise.all([
          axios.get(`${API_BASE_URL}/storefront/monitor`, { withCredentials: true }),
          axios.get(`${API_BASE_URL}/store/results/summary`, { withCredentials: true })
        ]);

        const storeNameMap = {};
        summaryResponse.data.stores.forEach(store => {
          storeNameMap[store.store_id] = store.store_name;
        });

        setStoreNames(storeNameMap);
        setTasks(tasksResponse.data);
      } catch (err) {
        setError('Failed to fetch monitor data');
        console.error('Error fetching monitor data:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleString('en-GB', {
      dateStyle: 'medium',
      timeStyle: 'short'
    });
  };

  const handleDeleteTask = async (taskId) => {
    if (!window.confirm('Are you sure you want to delete this monitor?')) {
      return;
    }

    try {
      await axios.delete(`${API_BASE_URL}/storefront/monitor/${taskId}`, {
        withCredentials: true
      });
      
      // Remove the task from the local state
      setTasks(tasks.filter(task => task.id !== taskId));
    } catch (err) {
      alert('Failed to delete monitor. Please try again.');
      console.error('Error deleting monitor:', err);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg w-full max-w-2xl flex flex-col max-h-[90vh]">
        {/* Fixed Header */}
        <div className="p-6 border-b">
          <div className="flex justify-between items-center">
            <h2 className="text-xl font-bold">Storefront Monitor Tasks</h2>
            <button 
              onClick={onClose}
              className="text-gray-500 hover:text-gray-700"
            >
              <FaTimes />
            </button>
          </div>
        </div>

        {/* Scrollable Content */}
        <div className="flex-1 overflow-y-auto p-6">
          {loading ? (
            <div className="flex justify-center items-center py-8">
              <FaSpinner className="animate-spin text-purple-600 text-2xl" />
            </div>
          ) : error ? (
            <div className="text-red-500 text-center py-4">
              {error}
            </div>
          ) : tasks.length === 0 ? (
            <div className="text-center py-8 text-gray-500">
              No monitored storefronts found. Add a storefront to start monitoring.
            </div>
          ) : (
            <div className="space-y-4">
              {tasks.map((task) => (
                <div 
                  key={task.id} 
                  className="border rounded-lg p-4 hover:shadow-md transition-shadow"
                >
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <h3 className="font-medium">
                        {storeNames[task.storefront_id] || 'Unknown Store'}
                      </h3>
                      <p className="text-sm text-gray-600 mt-1">
                        ID: {task.storefront_id}
                      </p>
                    </div>
                    <div className="text-right">
                      <span className={`px-2 py-1 rounded-full text-xs ${
                        task.active ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
                      }`}>
                        {task.active ? 'Active' : 'Inactive'}
                      </span>
                    </div>
                  </div>
                  
                  <div className="mt-3 text-sm text-gray-600">
                    <div>
                      <span className="font-medium">Created:</span>{' '}
                      {formatDate(task.created_at)}
                    </div>
                    <div>
                      <span className="font-medium">Last Check:</span>{' '}
                      {task.last_check_time ? formatDate(task.last_check_time) : 'Never'}
                    </div>
                  </div>

                  <div className="mt-3 flex justify-end gap-2">
                    <button
                      onClick={() => window.open(`https://www.amazon.co.uk/sp?seller=${task.storefront_id}`, '_blank')}
                      className="text-sm text-blue-600 hover:text-blue-800 flex items-center gap-1"
                    >
                      <FaExternalLinkAlt className="w-3 h-3" /> View Store
                    </button>
                    <button
                      onClick={() => handleDeleteTask(task.id)}
                      className="text-sm text-red-600 hover:text-red-800 flex items-center gap-1"
                    >
                      <FaTrash className="w-3 h-3" /> Delete
                    </button>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>

        {/* Fixed Footer */}
        <div className="p-6 border-t">
          <div className="flex justify-end">
            <button
              onClick={onClose}
              className="inline-flex items-center gap-2 px-4 py-2 bg-[#8957e5] text-white rounded-md hover:bg-[#7548d5] transition-colors"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const FiltersForm = ({ filters, onFilterChange, onSubmit }) => {
  return (
    <form onSubmit={onSubmit} className="mb-6">
      <div className="grid grid-cols-1 md:grid-cols-6 gap-4">
        <select
          name="category"
          value={filters.category}
          onChange={onFilterChange}
          className="p-2 border rounded-md w-full"
        >
          <option value="All Categories">All Categories</option>
          <option value="Health & Personal Care">Health & Personal Care</option>
          <option value="PC & Video Games">PC & Video Games</option>
          <option value="Stationery & Office Supplies">Stationery & Office Supplies</option>
          <option value="Automotive">Automotive</option>
          <option value="Pet Supplies">Pet Supplies</option>
          <option value="DVD & Blu-ray">DVD & Blu-ray</option>
          <option value="DIY & Tools">DIY & Tools</option>
          <option value="Business, Industry & Science">Business, Industry & Science</option>
          <option value="Garden">Garden</option>
          <option value="Toys & Games">Toys & Games</option>
          <option value="Baby Products">Baby Products</option>
          <option value="Beauty">Beauty</option>
          <option value="Home & Kitchen">Home & Kitchen</option>
          <option value="Sports & Outdoors">Sports & Outdoors</option>
          <option value="Fashion">Fashion</option>
          <option value="Lighting">Lighting</option>
          <option value="Electronics & Photo">Electronics & Photo</option>
          <option value="Grocery">Grocery</option>
          <option value="Computers & Accessories">Computers & Accessories</option>
          <option value="CDs & Vinyl">CDs & Vinyl</option>
          <option value="Musical Instruments & DJ">Musical Instruments & DJ</option>
        </select>
        
        <input
          type="number"
          name="minBBPrice"
          placeholder="Min BB Price"
          value={filters.minBBPrice}
          onChange={onFilterChange}
          className="p-2 border rounded-md"
        />
        
        <input
          type="number"
          name="minMonthlySales"
          placeholder="Min Monthly Sales"
          value={filters.minMonthlySales}
          onChange={onFilterChange}
          className="p-2 border rounded-md"
        />
        
        <input
          type="number"
          name="maxASINRank"
          placeholder="Max ASIN Rank"
          value={filters.maxASINRank}
          onChange={onFilterChange}
          className="p-2 border rounded-md"
        />

        <input
          type="number"
          name="minRankDrops"
          placeholder="Min Rank Drops (30d)"
          value={filters.minRankDrops}
          onChange={onFilterChange}
          className="p-2 border rounded-md"
        />
        
        <div className="flex">
          <input
            type="text"
            name="searchTerm"
            placeholder="Search by title"
            value={filters.searchTerm}
            onChange={onFilterChange}
            className="p-2 border rounded-md flex-grow"
          />
        </div>
      </div>
    </form>
  );
};

const StorefrontInterface = () => {
  const [allResults, setAllResults] = useState([]); // Store all unfiltered results
  const [filteredResults, setFilteredResults] = useState([]); // Store filtered results
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showAddTaskModal, setShowAddTaskModal] = useState(false);
  const [showTasksModal, setShowTasksModal] = useState(false);
  const [selectedStorefront, setSelectedStorefront] = useState(null);
  const [filters, setFilters] = useState({
    minBBPrice: '',
    minMonthlySales: '',
    maxASINRank: '',
    minRankDrops: '',
    category: 'All Categories',
    searchTerm: ''
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [resultsPerPage] = useState(100);
  const [totalPages, setTotalPages] = useState(1);
  const [displayedResults, setDisplayedResults] = useState([]);

  const fetchResults = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(`${API_BASE_URL}/store/results`, {
        withCredentials: true
      });
      setAllResults(response.data.results);
      applyFilters(response.data.results, filters);
    } catch (err) {
      setError('Failed to fetch results. Please try again.');
      console.error('Error fetching results:', err);
    } finally {
      setLoading(false);
    }
  };

  // Calculate profit and ROI for a product
  const calculateMetrics = (product) => {
    const profit = product.current_price_bb - product.current_price_amazon;
    const roi = product.current_price_amazon > 0 
      ? (profit / product.current_price_amazon) * 100 
      : 0;
    return { profit, roi };
  };

  // Apply filters to results
  const applyFilters = (results, currentFilters) => {
    let filtered = [...results];
  
    // Category filter
    if (currentFilters.category && currentFilters.category !== 'All Categories') {
      filtered = filtered.filter(product => product.category === currentFilters.category);
    }
  
    // Min BB Price filter
    if (currentFilters.minBBPrice) {
      filtered = filtered.filter(product => 
        product.current_price_bb >= parseFloat(currentFilters.minBBPrice)
      );
    }
  
    // Min Monthly Sales filter
    if (currentFilters.minMonthlySales) {
      filtered = filtered.filter(product => 
        product.monthly_sales >= parseFloat(currentFilters.minMonthlySales)
      );
    }
  
    // Max ASIN Rank filter
    if (currentFilters.maxASINRank) {
      filtered = filtered.filter(product => 
        product.asin_rank && product.asin_rank <= parseFloat(currentFilters.maxASINRank)
      );
    }
  
    // Min Rank Drops filter
    if (currentFilters.minRankDrops) {
      filtered = filtered.filter(product => 
        product.sales_rank_drops_30 >= parseFloat(currentFilters.minRankDrops)
      );
    }
  
    // Search term filter
    if (currentFilters.searchTerm) {
      const searchLower = currentFilters.searchTerm.toLowerCase();
      filtered = filtered.filter(product => 
        product.title.toLowerCase().includes(searchLower)
      );
    }
  
    setFilteredResults(filtered);
  };

  // Effect to apply filters whenever they change
  useEffect(() => {
    // First apply filters
    let filtered = [...allResults];
    
    if (filters.category && filters.category !== 'All Categories') {
      filtered = filtered.filter(product => product.category === filters.category);
    }

    if (filters.minBBPrice) {
      filtered = filtered.filter(product => 
        product.current_price_bb >= parseFloat(filters.minBBPrice)
      );
    }

    if (filters.minMonthlySales) {
      filtered = filtered.filter(product => 
        product.monthly_sales >= parseFloat(filters.minMonthlySales)
      );
    }

    if (filters.maxASINRank) {
      filtered = filtered.filter(product => 
        product.asin_rank && product.asin_rank <= parseFloat(filters.maxASINRank)
      );
    }

    if (filters.minRankDrops) {
      filtered = filtered.filter(product => 
        product.sales_rank_drops_30 >= parseFloat(filters.minRankDrops)
      );
    }

    if (filters.searchTerm) {
      const searchLower = filters.searchTerm.toLowerCase();
      filtered = filtered.filter(product => 
        product.title.toLowerCase().includes(searchLower)
      );
    }

    setFilteredResults(filtered);
    setTotalPages(Math.ceil(filtered.length / resultsPerPage));

    // Calculate pagination slice
    const startIndex = (currentPage - 1) * resultsPerPage;
    const endIndex = startIndex + resultsPerPage;
    setDisplayedResults(filtered.slice(startIndex, endIndex));

  }, [filters, allResults, currentPage, resultsPerPage]);

  // Initial fetch
  useEffect(() => {
    fetchResults();
  }, []);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters(prev => ({
      ...prev,
      [name]: value
    }));
    setCurrentPage(1); // Reset to first page when filters change
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    fetchResults(); // Refresh data from API
  };

  const handleAddTask = async (taskData) => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/storefront/monitor`,
        taskData,
        { withCredentials: true }
      );

      if (response.status === 201) {
        setShowAddTaskModal(false);
        setSelectedStorefront(null);
        alert('Task added successfully!');
      }
    } catch (err) {
      alert(err.response?.data?.error || 'Failed to add task. Please try again.');
      console.error('Error adding task:', err);
    }
  };

  const renderPagination = () => (
    <div className="flex items-center justify-between px-4 py-3 bg-white border-t border-gray-200 sm:px-6">
      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            Showing{' '}
            <span className="font-medium">
              {Math.min((currentPage - 1) * resultsPerPage + 1, filteredResults.length)}
            </span>
            {' '}-{' '}
            <span className="font-medium">
              {Math.min(currentPage * resultsPerPage, filteredResults.length)}
            </span>
            {' '}of{' '}
            <span className="font-medium">{filteredResults.length}</span>
            {' '}results
          </p>
        </div>
        <div>
          <nav className="inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
            <button
              onClick={() => setCurrentPage(1)}
              disabled={currentPage === 1}
              className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-50"
            >
              First
            </button>
            <button
              onClick={() => setCurrentPage(prev => Math.max(1, prev - 1))}
              disabled={currentPage === 1}
              className="relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-50"
            >
              Previous
            </button>
            <span className="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700">
              Page {currentPage} of {totalPages}
            </span>
            <button
              onClick={() => setCurrentPage(prev => Math.min(totalPages, prev + 1))}
              disabled={currentPage === totalPages}
              className="relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-50"
            >
              Next
            </button>
            <button
              onClick={() => setCurrentPage(totalPages)}
              disabled={currentPage === totalPages}
              className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-50"
            >
              Last
            </button>
          </nav>
        </div>
      </div>
    </div>
  );

  return (
    <div className="w-full max-w-7xl mx-auto p-6">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold text-gray-800"></h1>
        <div className="flex gap-2">
          <button 
            className="inline-flex items-center gap-2 px-4 py-2 bg-[#8957e5] text-white rounded-md hover:bg-[#7548d5] transition-colors"
            onClick={() => {
              setSelectedStorefront(null);
              setShowAddTaskModal(true);
            }}
          >
            <FaPlus /> <span>Add Task</span>
          </button>
          <button 
            className="inline-flex items-center gap-2 px-4 py-2 bg-[#8957e5] text-white rounded-md hover:bg-[#7548d5] transition-colors"
            onClick={() => setShowTasksModal(true)}
          >
            <FaTasks /> <span>View Tasks</span>
          </button>
        </div>
      </div>

      <FiltersForm 
        filters={filters}
        onFilterChange={handleFilterChange}
        onSubmit={handleSubmit}
      />

      {loading ? (
        <div className="flex items-center justify-center p-12">
          <FaSpinner className="animate-spin text-4xl text-purple-600" />
        </div>
      ) : error ? (
        <div className="text-red-500 p-4 text-center">
          {error}
        </div>
      ) : (
        <>
          <div className="bg-purple-50 p-4 rounded-lg mb-6">
            <h2 className="text-xl font-bold mb-2">Store Results</h2>
            <div className="text-sm text-gray-600">
              Showing {displayedResults.length} of {filteredResults.length} products
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {displayedResults.map((product) => (
              <ProductCard key={product.id} product={product} />
            ))}
          </div>

          {filteredResults.length > 0 ? (
            <div className="mt-6">
              {renderPagination()}
            </div>
          ) : (
            <div className="text-center text-gray-500 py-12">
              No results found matching your filters. Try adjusting your criteria.
            </div>
          )}
        </>
      )}

      {showAddTaskModal && (
        <AddTaskModal
          onClose={() => {
            setShowAddTaskModal(false);
            setSelectedStorefront(null);
          }}
          onSubmit={handleAddTask}
          storefront={selectedStorefront}
        />
      )}

      {showTasksModal && (
        <TasksModal
          onClose={() => setShowTasksModal(false)}
        />
      )}
    </div>
  );
};



      // Utility function to format currency
      const formatCurrency = (value) => {
        if (value === null || value === undefined) return 'N/A';
        return `£${value.toFixed(2)}`;
      };

      // Utility function to format date
      const formatDate = (dateString) => {
        if (!dateString) return 'N/A';
        return new Date(dateString).toLocaleString('en-GB', {
          dateStyle: 'medium',
          timeStyle: 'short'
        });
      };

      

      // Export the component
      export default StorefrontInterface;
