import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, CartesianGrid } from 'recharts';
import { AreaChart, Area } from 'recharts';
import { Title } from '@tremor/react';

const API_BASE_URL = 'https://profithunter.app/api';

const StatCircle = ({ current, previous, label, isPercentage = false, color }) => {
  const size = 180;
  const strokeWidth = 6;
  const radius = (size - strokeWidth) / 2;
  const circumference = radius * 2 * Math.PI;

  // Always show full circle with color
  const dashOffset = 0;

  // Format display value based on label type
  const getDisplayValue = (value, label) => {
    switch (label) {
      case 'ROI':
        return `${Math.round(value)}%`;
      case 'ITEMS':
        return Math.round(value);
      case 'PROFIT':
      case 'SPENT':
        return `£${value?.toFixed(2)}`;
      default:
        return value?.toFixed(2);
    }
  };

  return (
    <div className="stat-circle-container">
      <svg className="progress-ring" width={size} height={size}>
        <circle
          stroke="#2c2c2c" // Dark background circle
          strokeWidth={strokeWidth}
          fill="none"
          r={radius}
          cx={size / 2}
          cy={size / 2}
        />
        <circle
          stroke={color} // Use the passed color prop
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          fill="none"
          r={radius}
          cx={size / 2}
          cy={size / 2}
          style={{
            strokeDasharray: circumference,
            strokeDashoffset: dashOffset,
          }}
        />
      </svg>
      <div className="stat-content">
        <div className="stat-value text-white">{getDisplayValue(current, label)}</div>
        <div className="stat-label" style={{ color }}>{label}</div>
      </div>
    </div>
  );
};

const Dashboard = ({ 
  totalResults, setTotalResults,
  notificationCount, setNotificationCount,
  activeMonitors, setActiveMonitors,
  setSelectedOption 
}) => {
  const [timeframe, setTimeframe] = useState('daily');
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [selectedSource, setSelectedSource] = useState('all');
  const [storeResults, setStoreResults] = useState([]);
  const [selectedStore, setSelectedStore] = useState('');
  const [storeNames, setStoreNames] = useState([]);
  const [purchaseStats, setPurchaseStats] = useState({
    all_time: { items_bought: 0, roi: 0, total_profit: 0, total_spent: 0 },
    daily: { items_bought: 0, roi: 0, total_profit: 0, total_spent: 0 },
    monthly: { items_bought: 0, roi: 0, total_profit: 0, total_spent: 0 },
    yearly: { items_bought: 0, roi: 0, total_profit: 0, total_spent: 0 },
    by_category: {},
    by_source: {}
  });
  const [storefrontsMonitored, setStorefrontsMonitored] = useState(0);
  const [storefrontResults, setStorefrontResults] = useState(0);

  // Fetch store data function
  const fetchStoreData = async () => {
    try {
      // Get active tasks count from storefront stats
      const statsResponse = await axios.get(`${API_BASE_URL}/storefront/stats`, {
        withCredentials: true
      });
      
      // Get total results from store results summary
      const resultsResponse = await axios.get(`${API_BASE_URL}/store/results/summary`, {
        withCredentials: true
      });
      
      if (statsResponse.data) {
        // Get active tasks count from stats endpoint
        const activeTasks = statsResponse.data.active_tasks || 0;
        setStorefrontsMonitored(activeTasks);
      }

      if (resultsResponse.data) {
        // Get total results from summary endpoint
        setStorefrontResults(resultsResponse.data.total_results || 0);
      }
    } catch (error) {
      console.error('Error fetching store data:', error);
    }
  };

  // Fetch store results data for graph
  const fetchStoreResultsData = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/store/results`, {
        withCredentials: true
      });
      
      if (response.data?.results) {
        setStoreResults(response.data.results);
        
        // Get unique store names for filter
        const uniqueStores = [...new Set(response.data.results.map(result => result.store_name))];
        setStoreNames(uniqueStores);
        
        // Set default selected store to the first one
        if (uniqueStores.length > 0) {
          setSelectedStore(uniqueStores[0]);
        }
      }
    } catch (error) {
      console.error('Error fetching store results:', error);
    }
  };

  // Process data for graph
  const getProcessedData = () => {
    const filteredResults = storeResults.filter(result => result.store_name === selectedStore);

    const groupedData = filteredResults.reduce((acc, result) => {
      const date = new Date(result.timestamp).toLocaleDateString();
      
      if (!acc[date]) {
        acc[date] = { date, count: 0 };
      }
      
      acc[date].count++;
      return acc;
    }, {});

    return Object.values(groupedData);
  };

  // Initial fetch of purchase stats
  useEffect(() => {
    const fetchStats = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/purchases/stats`, {
          withCredentials: true
        });
        setPurchaseStats({
          all_time: {
            total_spent: 0,
            total_profit: 0,
            items_bought: 0,
            roi: 0,
            ...response.data.all_time
          },
          daily: {
            total_spent: 0,
            total_profit: 0,
            items_bought: 0,
            roi: 0,
            ...response.data.daily
          },
          monthly: {
            total_spent: 0,
            total_profit: 0,
            items_bought: 0,
            roi: 0,
            ...response.data.monthly
          },
          yearly: {
            total_spent: 0,
            total_profit: 0,
            items_bought: 0,
            roi: 0,
            ...response.data.yearly
          },
          by_category: response.data.by_category || {},
          by_source: response.data.by_source || {}
        });
      } catch (error) {
        console.error('Error fetching purchase stats:', error);
      }
    };
    
    fetchStats();
  }, []);

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        // Fetch total results
        const totalResultsResponse = await axios.get(`${API_BASE_URL}/total_results`);
        if (totalResultsResponse.data.total_results !== undefined) {
          setTotalResults(totalResultsResponse.data.total_results);
        }

        // Fetch notification count
        const notificationResponse = await axios.get(`${API_BASE_URL}/notifications/count`, {
          withCredentials: true
        });
        if (notificationResponse.data.count !== undefined) {
          setNotificationCount(notificationResponse.data.count);
        }

        // Fetch active monitors
        const monitorsResponse = await axios.get(`${API_BASE_URL}/price-monitor/active-count`, {
          withCredentials: true
        });
        if (monitorsResponse.data.count !== undefined) {
          setActiveMonitors(monitorsResponse.data.count);
        }

        // Fetch store data
        await fetchStoreData();
        
        // Fetch store results data for graph
        await fetchStoreResultsData();

      } catch (error) {
        console.error('Error fetching initial data:', error);
      }
    };

    fetchInitialData();
  }, []);

  // Auto-update purchase stats
  useEffect(() => {
    const interval = setInterval(() => {
      const fetchStats = async () => {
        try {
          const response = await axios.get(`${API_BASE_URL}/purchases/stats`, {
            withCredentials: true
          });
          setPurchaseStats({
            all_time: {
              total_spent: 0,
              total_profit: 0,
              items_bought: 0,
              roi: 0,
              ...response.data.all_time
            },
            daily: {
              total_spent: 0,
              total_profit: 0,
              items_bought: 0,
              roi: 0,
              ...response.data.daily
            },
            monthly: {
              total_spent: 0,
              total_profit: 0,
              items_bought: 0,
              roi: 0,
              ...response.data.monthly
            },
            yearly: {
              total_spent: 0,
              total_profit: 0,
              items_bought: 0,
              roi: 0,
              ...response.data.yearly
            },
            by_category: response.data.by_category || {},
            by_source: response.data.by_source || {}
          });
        } catch (error) {
          console.error('Error fetching purchase stats:', error);
        }
      };
      fetchStats();
    }, 60000); // 60 seconds

    return () => clearInterval(interval);
  }, []);

  // Auto-update counters
  useEffect(() => {
    const interval = setInterval(async () => {
      try {
        // Fetch total results
        const totalResultsResponse = await axios.get(`${API_BASE_URL}/total_results`);
        if (totalResultsResponse.data.total_results !== undefined) {
          setTotalResults(totalResultsResponse.data.total_results);
        }

        // Fetch notification count
        const notificationResponse = await axios.get(`${API_BASE_URL}/notifications/count`, {
          withCredentials: true
        });
        if (notificationResponse.data.count !== undefined) {
          setNotificationCount(notificationResponse.data.count);
        }

        // Fetch active monitors
        const monitorsResponse = await axios.get(`${API_BASE_URL}/price-monitor/active-count`, {
          withCredentials: true
        });
        if (monitorsResponse.data.count !== undefined) {
          setActiveMonitors(monitorsResponse.data.count);
        }

        // Fetch store data
        await fetchStoreData();

        // Fetch store results data for graph
        await fetchStoreResultsData();

      } catch (error) {
        console.error('Error fetching updates:', error);
      }
    }, 60000); // 60 seconds

    return () => clearInterval(interval);
  }, [setTotalResults, setNotificationCount, setActiveMonitors]);

  const getCurrentStats = () => {
    if (selectedCategory !== 'all') {
      return purchaseStats.by_category[selectedCategory] || {};
    }
    if (selectedSource !== 'all') {
      return purchaseStats.by_source[selectedSource] || {};
    }
    return purchaseStats[timeframe] || {};
  };

  const currentStats = getCurrentStats();

  return (
    <div className="dashboard-layout">
      {/* Overview Cards Section */}
      <div className="overview-container mb-6">
        <div className="overview-cards grid grid-cols-5 gap-4">
          <div 
            className="stat-card bg-gray-700 dark:bg-gray-900 cursor-pointer" 
            onClick={() => setSelectedOption('a2aDatabase')}
          >
            <h3 className="text-gray-400">A2A Database</h3>
            <div className="stat-value text-white">{totalResults || 0}</div>
          </div>
          
          <div 
            className="stat-card bg-gray-700 dark:bg-gray-900 cursor-pointer" 
            onClick={() => setSelectedOption('a2aNotifications')}
          >
            <h3 className="text-gray-400">A2A Notifications</h3>
            <div className="stat-value text-white">{notificationCount || 0}</div>
          </div>
          
          <div 
            className="stat-card bg-gray-700 dark:bg-gray-900 cursor-pointer" 
            onClick={() => setSelectedOption('asinMonitor')}
          >
            <h3 className="text-gray-400">ASIN Monitor</h3>
            <div className="stat-value text-white">{activeMonitors || 0}</div>
          </div>

          <div 
            className="stat-card bg-gray-700 dark:bg-gray-900 cursor-pointer" 
            onClick={() => setSelectedOption('storefrontHunter')}
          >
            <h3 className="text-gray-400">Storefronts Monitored</h3>
            <div className="stat-value text-white">{storefrontsMonitored}</div>
          </div>
          
          <div 
            className="stat-card bg-gray-700 dark:bg-gray-900 cursor-pointer" 
            onClick={() => setSelectedOption('storefrontHunter')}
          >
            <h3 className="text-gray-400">Storefront Results</h3>
            <div className="stat-value text-white">{storefrontResults}</div>
          </div>
        </div>
      </div>

      {/* Performance Section */}
      <div className="performance-cards-container grid grid-cols-2 gap-4">
        {/* First Card - A2A Performance */}
        <div className="performance-section bg-gray-800 dark:bg-gray-900 p-6 rounded-lg shadow-lg">
          <div className="performance-header flex justify-between items-center">
            <Title className="text-black">A2A Performance</Title>
            <div className="filter-container flex space-x-2">
              <select 
                value={timeframe}
                onChange={(e) => setTimeframe(e.target.value)}
                className="filter-select bg-gray-700 dark:bg-gray-800 text-gray-300"
              >
                <option value="daily">Today</option>
                <option value="monthly">Monthly</option>
                <option value="yearly">Yearly</option>
                <option value="all_time">Lifetime</option>
              </select>

              <select
                value={selectedCategory}
                onChange={(e) => {
                  setSelectedCategory(e.target.value);
                  setSelectedSource('all');
                }}
                className="filter-select bg-gray-700 dark:bg-gray-800 text-gray-300"
              >
                <option value="all">All Categories</option>
                {Object.keys(purchaseStats.by_category || {}).map(category => (
                  <option key={category} value={category}>{category}</option>
                ))}
              </select>

              <select
                value={selectedSource}
                onChange={(e) => {
                  setSelectedSource(e.target.value);
                  setSelectedCategory('all');
                }}
                className="filter-select bg-gray-700 dark:bg-gray-800 text-gray-300"
              >
                <option value="all">All Sources</option>
                {Object.keys(purchaseStats.by_source || {}).map(source => (
                  <option key={source} value={source}>{source}</option>
                ))}
              </select>
            </div>
          </div>

          <div className="stats-container">
            <StatCircle
              current={currentStats.roi || 0}
              previous={purchaseStats.daily.roi || 0}
              label="ROI"
              isPercentage={true}
              color="#C4B5FD"
            />
            <StatCircle
              current={currentStats.total_profit || 0}
              previous={purchaseStats.daily.total_profit || 0}
              label="PROFIT"
              color="#718096"
            />
            <StatCircle
              current={currentStats.items_bought || 0}
              previous={purchaseStats.daily.items_bought || 0}
              label="ITEMS"
              color="#7C3AED"
            />
            <StatCircle
              current={currentStats.total_spent || 0}
              previous={purchaseStats.daily.total_spent || 0}
              label="SPENT"
              color="#4A5568"
            />
          </div>
        </div>

        {/* Second Card - Storefront Stats with Graph */}
        <div className="performance-section bg-gray-100 dark:bg-gray-800 p-6 rounded-lg shadow-lg">
          <div className="performance-header flex justify-between items-center mb-4">
            <Title className="text-black">Storefront Results</Title>
            <select
              value={selectedStore}
              onChange={(e) => setSelectedStore(e.target.value)}
              className="filter-select bg-gray-800 text-gray-200 rounded-md p-2 text-sm"
            >
              {storeNames.map(store => (
                <option key={store} value={store}>{store}</option>
              ))}
            </select>
          </div>
          
          <div className="mt-4" style={{ height: '300px' }}>
            <ResponsiveContainer width="100%" height="100%">
              <AreaChart data={getProcessedData()}>
                <defs>
                  <linearGradient id="colorCount" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#9F7AEA" stopOpacity={0.8}/>
                    <stop offset="95%" stopColor="#9F7AEA" stopOpacity={0.1}/>
                  </linearGradient>
                </defs>
                <Area 
                  type="monotone" 
                  dataKey="count" 
                  stroke="#9F7AEA"
                  fillOpacity={1}
                  fill="url(#colorCount)"
                  strokeWidth={2}
                />
                <Tooltip 
                  contentStyle={{ 
                    backgroundColor: '#333', 
                    border: 'none',
                    borderRadius: '8px',
                    padding: '8px'
                  }}
                  labelStyle={{ color: '#fff' }}
                  itemStyle={{ color: '#fff' }}
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
