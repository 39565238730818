import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FaDiscord, FaGoogle } from 'react-icons/fa';

function Login() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get('token');
    
    if (token) {
      localStorage.setItem('token', token);
      navigate('/dashboard');
    }

    const error = params.get('error');
    if (error) {
      console.error('Authentication error:', error);
    }
  }, [navigate, location]);

  const handleDiscordLogin = () => {
    window.location.href = 'https://profithunter.app/api/auth/discord';
  };

  const handleGoogleLogin = () => {
    window.location.href = 'https://profithunter.app/api/auth/google';
  };

  return (
    <div className="min-h-screen w-full flex items-center justify-center bg-gray-100 dark:bg-gray-900 fixed inset-0">
      <div className="bg-white dark:bg-gray-800 p-8 rounded-xl shadow-lg w-96 transform transition-all hover:scale-105 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
        <div className="text-center mb-8">
          <h2 className="text-3xl font-bold text-gray-800 dark:text-white mb-2">
            Profit Hunter
          </h2>
          <p className="text-gray-500 dark:text-gray-400">
            Sign in to continue
          </p>
        </div>

        <div className="space-y-4">
          <button
            onClick={handleDiscordLogin}
            className="w-full flex items-center justify-center gap-3 bg-[#5865F2] hover:bg-[#4752C4] text-white py-3 px-4 rounded-lg font-medium transition-colors duration-200"
          >
            <FaDiscord className="text-xl" />
            <span>Login with Discord</span>
          </button>

          <button
            onClick={handleGoogleLogin}
            className="w-full flex items-center justify-center gap-3 bg-white hover:bg-gray-50 dark:bg-gray-700 dark:hover:bg-gray-600 text-gray-700 dark:text-white border border-gray-300 dark:border-gray-600 py-3 px-4 rounded-lg font-medium transition-colors duration-200"
          >
            <FaGoogle className="text-xl" />
            <span>Login with Google</span>
          </button>
        </div>

        <div className="mt-6 text-center text-sm text-gray-500 dark:text-gray-400">
          By signing in, you agree to our Terms of Service and Privacy Policy
        </div>
      </div>
    </div>
  );
}

export default Login;
