import React, { useState, useEffect } from 'react';
import { FaTimes } from 'react-icons/fa';

const PurchaseModal = ({ isOpen, onClose, product, onSubmit }) => {
  const [formData, setPurchaseFormData] = useState({
    quantity: 1,
    purchase_price: 0,
  });

  useEffect(() => {
    if (product) {
      setPurchaseFormData({
        quantity: 1,
        purchase_price: product.current_price || 0,
      });
    }
  }, [product]);

  if (!isOpen || !product) return null;

  const handleSubmit = (e) => {
    e.preventDefault();
    
    const totalProfit = product.profit * formData.quantity;
  
    const submitData = {
      asin: product.asin,
      title: product.title,
      quantity: parseInt(formData.quantity),
      purchase_price: parseFloat(formData.purchase_price),
      source: product.source,
      category: product.category,
      roi_at_purchase: product.roi_percentage,
      avg_90day_price: product.avg_90day_price,
      total_profit: totalProfit
    };
  
    console.log('Submitting purchase with total profit:', totalProfit);
    onSubmit(submitData);
    onClose();
  };

  const calculateTotal = () => {
    return (formData.quantity * formData.purchase_price).toFixed(2);
  };

  const calculateTotalProfit = () => {
    return (product.profit * formData.quantity).toFixed(2);
  };

  const calculateROI = () => {
    return product.roi_percentage?.toFixed(2) || '0.00';
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg w-[900px] p-6"> {/* Increased width to 900px */}
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-semibold">Add Purchase</h2>
          <button className="text-gray-400 hover:text-gray-600" onClick={onClose}>
            <FaTimes />
          </button>
        </div>

        <div className="grid grid-cols-[300px,1fr] gap-6 mb-6">
          <div className="product-image">
            <img 
              src={`https://images-na.ssl-images-amazon.com/images/I/${product.image_url?.split(',')[0]}`}
              alt={product.title}
              className="w-full h-auto object-contain"
            />
          </div>
          <div className="product-info space-y-2">
            <h3 className="font-medium text-lg">{product.title}</h3>
            <p><strong>ASIN:</strong> {product.asin}</p>
            <p><strong>Current Price:</strong> £{product.current_price?.toFixed(2)}</p>
            <p><strong>90 Day Average:</strong> £{product.avg_90day_price?.toFixed(2)}</p>
            <p><strong>Category:</strong> {product.category}</p>
            <p><strong>Source:</strong> {product.source}</p>
          </div>
        </div>

        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="grid grid-cols-2 gap-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Quantity:</label>
              <input
                type="number"
                min="1"
                value={formData.quantity}
                onChange={(e) => setPurchaseFormData({
                  ...formData,
                  quantity: Math.max(1, parseInt(e.target.value) || 0)
                })}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Purchase Price (£):</label>
              <input
                type="number"
                step="0.01"
                min="0.01"
                value={formData.purchase_price}
                onChange={(e) => setPurchaseFormData({
                  ...formData,
                  purchase_price: Math.max(0.01, parseFloat(e.target.value) || 0)
                })}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500"
                required
              />
            </div>
          </div>

          <div className="bg-gray-50 rounded-lg p-4">
            <h3 className="font-medium mb-4">Purchase Summary</h3>
            <div className="grid grid-cols-3 gap-4">
              <div>
                <p className="text-sm text-gray-600">Price per unit:</p>
                <p className="font-medium">£{parseFloat(formData.purchase_price).toFixed(2)}</p>
              </div>
              <div>
                <p className="text-sm text-gray-600">Quantity:</p>
                <p className="font-medium">{formData.quantity}</p>
              </div>
              <div>
                <p className="text-sm text-gray-600">Total Cost:</p>
                <p className="font-medium">£{calculateTotal()}</p>
              </div>
              <div>
                <p className="text-sm text-gray-600">Potential Profit:</p>
                <p className="font-medium text-green-600">£{calculateTotalProfit()}</p>
              </div>
              <div>
                <p className="text-sm text-gray-600">ROI:</p>
                <p className="font-medium text-blue-600">{calculateROI()}%</p>
              </div>
            </div>
          </div>

          <div className="flex justify-end space-x-3">
            <button 
              type="button" 
              onClick={onClose} 
              className="px-4 py-2 bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200 w-[120px]"
            >
              Cancel
            </button>
            <button 
              type="submit" 
              className="px-4 py-2 bg-[#8957e5] text-white rounded-md hover:bg-[#7b4fd1] w-[120px]"
            >
              Confirm
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PurchaseModal; 