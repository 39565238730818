'use client'

import { useState, useEffect } from 'react'
import { ArrowRight, ArrowDown, CheckCircle, Database, Bell, CheckSquare, Clock, Check, Store, Filter, Search, Plus, Minus } from 'lucide-react'
import { useNavigate } from 'react-router-dom';

export default function Component() {
  const [profit, setProfit] = useState(0)
  const [time, setTime] = useState(60)
  const [showTime, setShowTime] = useState(false)
  const [screen, setScreen] = useState('profit')
  const [expandedIndex, setExpandedIndex] = useState(null)
  const navigate = useNavigate();

  useEffect(() => {
    const profitInterval = setInterval(() => {
      setProfit(prev => {
        if (prev < 1000000) {
          return prev + 5000
        } else {
          clearInterval(profitInterval)
          setTimeout(() => {
            setScreen('time')
            setShowTime(true)
          }, 2000)
          return 1000000
        }
      })
    }, 30)

    return () => clearInterval(profitInterval)
  }, [])

  useEffect(() => {
    if (showTime) {
      const timeInterval = setInterval(() => {
        setTime(prev => {
          if (prev > 5) {
            return prev - 1
          } else {
            clearInterval(timeInterval)
            return 5
          }
        })
      }, 50)

      return () => clearInterval(timeInterval)
    }
  }, [showTime])

  const thoughts = [
    "I've been sourcing all day and not found a single profitable lead.",
    "My spend is so low this month, where am I going to find good items to buy?",
    "I've received so many notifications, I don't know where to start.",
  ]

  const features = [
    {
      icon: Database,
      title: "A2A Database",
      description: "Receive access to every A2A found in one place. Easily filter to see data on brands, prices, profit and much more... Set up Price monitors in seconds"
    },
    {
      icon: Bell,
      title: "A2A Notifications",
      description: "Create your own custom private filters so you ONLY receive notifications that you want to see."
    },
    {
      icon: Clock,
      title: "A2A ASIN Monitor",
      description: "Monitor any item and receive a notification instantly so you never miss a price drop again. Speed is on your side with this custom monitor."
    },
    {
      icon: CheckSquare,
      title: "Easy Item Management",
      description: "Easily tick off items you have checked so you can concentrate on items that need checking."
    },
    {
      icon: Store,
      title: "Monitor Storefronts",
      description: "Monitor up to 10 Storefronts and receive instant notification when a new item is listed."
    },
    {
      icon: Filter,
      title: "Filter Items",
      description: "Filter Profitable items to focus on the most promising opportunities."
    },
    {
      icon: Search,
      title: "Automated Google Check",
      description: "Every item is checked against Google so you don't have to, saving you time and effort."
    }
  ]

  const faqs = [
    {
      question: "What marketplaces do you support?",
      answer: "We currently support Amazon marketplaces in the US, UK, Germany, France, Italy, Spain, and Canada. We're constantly expanding our coverage to include more marketplaces."
    },
    {
      question: "How many ASINs can I monitor?",
      answer: "With our Basic plan, you can monitor up to 100 ASINs. Our Pro plan allows for unlimited ASIN monitoring."
    },
    {
      question: "What is an A2A lead?",
      answer: "An A2A (Amazon to Amazon) lead is a product opportunity where you can profitably source an item from one Amazon marketplace and sell it on another."
    },
    {
      question: "How often are prices updated?",
      answer: "We update prices and stock information every 30 minutes for all monitored ASINs."
    },
    {
      question: "Will using Profit Hunter affect my Amazon account?",
      answer: "No, Profit Hunter is a third-party tool that doesn't interact directly with your Amazon account. It only analyzes publicly available data."
    },
    {
      question: "Can I cancel anytime?",
      answer: "Yes, you can cancel your subscription at any time. Your access will continue until the end of your current billing period."
    },
    {
      question: "Do you offer a refund?",
      answer: "We offer a 7-day money-back guarantee for new subscribers. If you're not satisfied with our service, you can request a full refund within the first 7 days of your subscription."
    },
    {
      question: "I have another question",
      answer: "If you have any other questions, please don't hesitate to contact our support team. We're here to help!"
    }
  ]

  const toggleQuestion = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index)
  }

  return (
    <div className="min-h-screen bg-black text-white w-full overflow-x-hidden">
      <header className="border-b border-gray-800">
        <div className="container mx-auto flex items-center justify-between px-4 py-4 max-w-7xl">
          <div className="flex items-center space-x-3">
            <img
              src="https://hebbkx1anhila5yf.public.blob.vercel-storage.com/Screenshot%202024-10-25%20at%2014.27.52-9vMmrwtQ51CudUygMtHtrFcKVPiB9d.png"
              alt="Profit Hunter Logo"
              className="h-10 w-10"
            />
            <span className="text-xl font-semibold text-white">Profit Hunter</span>
          </div>
          <nav>
            <ul className="flex space-x-6">
              <li>
                <a href="#pricing" className="text-gray-300 hover:text-white">
                  Pricing
                </a>
              </li>
              <li>
                <a href="#features" className="text-gray-300 hover:text-white">
                  Features
                </a>
              </li>
              <li>
                <a href="#faq" className="text-gray-300 hover:text-white">
                  FAQ
                </a>
              </li>
            </ul>
          </nav>
          <button 
            onClick={() => navigate('/login')} 
            className="rounded-md bg-purple-600 px-4 py-2 text-sm font-medium text-white hover:bg-purple-700"
          >
            Login
          </button>
        </div>
      </header>
      <main className="w-full overflow-x-hidden">
        <section className="container mx-auto px-4 py-16 max-w-7xl">
          <div className="grid gap-12 md:grid-cols-2">
            <div className="flex flex-col justify-center">
              <h1 className="mb-4 text-6xl font-bold leading-tight tracking-tighter">
                <span className="text-purple-400">Maximise Profits</span>,{' '}
                <span className="text-white">Minimise Time</span>.
              </h1>
              <p className="mb-8 text-xl text-gray-300">
                Discover a faster, more profitable way to source products for Amazon. Our web app streamlines the process,
                helping you find high-profit items in record time.
              </p>
              <div className="mb-8">
                <a href="#pricing" className="inline-flex items-center rounded-md bg-purple-600 px-6 py-3 text-lg font-semibold text-white hover:bg-purple-700">
                  Get Profit Hunter
                  <ArrowRight className="ml-2 h-5 w-5" />
                </a>
              </div>
            </div>
            <div className="flex items-center justify-center rounded-lg bg-gray-900 p-8">
              <div className="text-center">
                {screen === 'profit' && (
                  <>
                    <h2 className="mb-2 text-2xl font-semibold text-white">Watch profits grow</h2>
                    <div className="text-6xl font-bold text-purple-400">
                      £{profit.toLocaleString()}+
                    </div>
                    <p className="mt-2 text-gray-300">Potential profits await...</p>
                  </>
                )}
                {screen === 'time' && (
                  <>
                    <h2 className="mb-2 text-2xl font-semibold text-white">Time Spent Sourcing</h2>
                    <div className="text-6xl font-bold text-purple-400">{time} hours</div>
                  </>
                )}
              </div>
            </div>
          </div>
        </section>
        
        {/* Cascading Thoughts Section */}
        <section className="mt-16 max-w-4xl mx-auto px-4">
          <h3 className="text-2xl font-bold text-gray-300 mb-4">Before Using Profit Hunter</h3>
          <div className="space-y-4 mb-8">
            {thoughts.map((thought, index) => (
              <div
                key={index}
                className="bg-gray-800 border border-gray-700 rounded-lg p-4 shadow-md"
                style={{ marginLeft: `${index * 5}%`, maxWidth: '90%' }}
              >
                <p className="text-gray-300">{thought}</p>
              </div>
            ))}
          </div>
          <div className="flex justify-center my-8">
            <ArrowDown className="text-purple-400 h-12 w-12" />
          </div>
          <div className="bg-green-900 border border-green-700 rounded-lg p-6 shadow-md">
            <div className="flex items-start">
              <CheckCircle className="text-green-400 h-6 w-6 mr-2 flex-shrink-0 mt-1" />
              <div>
                <h3 className="text-2xl font-bold text-green-300 mb-4">After Using Profit Hunter</h3>
                <ul className="list-none space-y-2">
                  <li className="flex items-start">
                    <span className="text-green-400 mr-2">-</span>
                    <p className="text-green-200">
                      I've never bought so many items. My profit this month is through the roof.
                    </p>
                  </li>
                  <li className="flex items-center mb-2">
                    <span className="text-green-400 mr-2">-</span>
                    <p className="text-green-200">
                      I am spending more than ever before whilst spending less time on my business.
                    </p>
                  </li>
                  <li className="flex items-start">
                    <span className="text-green-400 mr-2">-</span>
                    <p className="text-green-200">
                      Now I manage the notifications with ease.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        {/* Statistics Banner */}
        <section className="mt-16 bg-purple-900 text-white py-12">
          <div className="container mx-auto px-4 max-w-7xl">
            <div className="grid grid-cols-1 md:grid-cols-4 gap-8 text-center">
              <div>
                <div className="text-4xl font-bold mb-2">300,000+</div>
                <div className="text-xl">Items Monitored</div>
              </div>
              <div>
                <div className="text-4xl font-bold mb-2">20+</div>
                <div className="text-xl">Unique Price Monitors</div>
              </div>
              <div>
                <div className="text-4xl font-bold mb-2">6+</div>
                <div className="text-xl">Countries</div>
              </div>
              <div>
                <div className="text-4xl font-bold mb-2">100+</div>
                <div className="text-xl">Leads Found Per Day</div>
              </div>
            </div>
          </div>
        </section>

        {/* Features Section */}
        <section id="features" className="mt-16 bg-gray-900 py-16">
          <div className="container mx-auto px-4 max-w-7xl">
            <h2 className="text-4xl font-bold text-center mb-12">
              One place to find profitable items <span className="text-purple-400">effortlessly</span>.
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {features.map((feature, index) => (
                <div 
                  key={index} 
                  className={`bg-gray-800 p-6 rounded-lg shadow-md ${
                    index === features.length - 1 ? "md:col-start-2 lg:col-start-2" : ""
                  }`}
                >
                  <feature.icon className="h-12 w-12 text-purple-400 mb-4" />
                  <h3 className="text-xl font-semibold mb-2 text-white">{feature.title}</h3>
                  <p className="text-gray-300">{feature.description}</p>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* Device Mockups Section */}
        <section className="mt-16 py-16 bg-gray-700">
          <div className="container mx-auto px-4 max-w-7xl">
            <h2 className="text-4xl font-bold text-center mb-12 text-white">
              Profit Hunter at Your Fingertips
            </h2>
            <div className="flex flex-col lg:flex-row justify-center items-center gap-12">
              {/* iPhone Mockup */}
              <div className="relative w-[300px] h-[600px] bg-black rounded-[60px] shadow-xl overflow-hidden border-[14px] border-gray-800">
                <div className="absolute top-0 inset-x-0 h-[32px] bg-black rounded-b-3xl"></div>
                <div className="absolute top-[10px] inset-x-0 h-[30px] w-[150px] mx-auto bg-black rounded-full"></div>
                <div className="h-full w-full bg-gray-900 pt-12">
                  {/* Notification 1 */}
                  <div className="bg-gray-800 mx-4 my-2 p-4 rounded-lg shadow">
                    <h3 className="font-semibold text-purple-400">Price Drop Alert!</h3>
                    <p className="text-sm text-gray-300">ASIN B00X12345 price dropped by 15%</p>
                  </div>
                  {/* Notification 2 */}
                  <div className="bg-gray-800 mx-4 my-2 p-4 rounded-lg shadow">
                    <h3  className="font-semibold text-purple-400">New Lead Found</h3>
                    <p className="text-sm text-gray-300">High-profit item detected in Electronics</p>
                    </div>
                  {/* Notification 3 */}
                  <div className="bg-gray-800 mx-4 my-2 p-4 rounded-lg shadow">
                    <h3 className="font-semibold text-purple-400">Sourcing Opportunity</h3>
                    <p className="text-sm text-gray-300">10 new items match your criteria</p>
                  </div>
                </div>
              </div>

              {/* Laptop Mockup */}
              <div className="relative w-full max-w-[600px] pt-[20px] bg-gray-800 rounded-t-xl overflow-hidden">
                <div className="absolute top-0 left-1/2 transform -translate-x-1/2 h-[20px] w-[100px] bg-gray-800 rounded-b-xl"></div>
                <div className="bg-gray-900 h-[400px] overflow-y-auto p-4">
                  <div className="flex justify-between items-center mb-4">
                    <h3 className="text-xl font-bold text-purple-400">Profit Hunter Dashboard</h3>
                    <div className="flex space-x-2">
                      <div className="w-3 h-3 rounded-full bg-red-500"></div>
                      <div className="w-3 h-3 rounded-full bg-yellow-500"></div>
                      <div className="w-3 h-3 rounded-full bg-green-500"></div>
                    </div>
                  </div>
                  <div className="grid grid-cols-2 gap-4">
                    <div className="bg-gray-800 p-4 rounded-lg shadow">
                      <h4 className="font-semibold text-purple-400 mb-2">Today's Profit</h4>
                      <p className="text-2xl font-bold text-white">£1,250</p>
                    </div>
                    <div className="bg-gray-800 p-4 rounded-lg shadow">
                      <h4 className="font-semibold text-purple-400 mb-2">New Leads</h4>
                      <p className="text-2xl font-bold text-white">67</p>
                    </div>
                    <div className="bg-gray-800 p-4 rounded-lg shadow col-span-2">
                      <h4  className="font-semibold text-purple-400 mb-2">Storefront Hunter</h4>
                      <ul className="list-none text-gray-300">
                        <li>10 Stores Monitored</li>
                        <li>125 Leads Found</li>
                        <li>£2000 Potential Profit</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-800 h-[20px] w-full"></div>
              </div>
            </div>
          </div>
        </section>

        {/* Call to Action Section */}
        <section className="mt-16 py-16 bg-gray-900">
          <div className="container mx-auto px-4 text-center max-w-7xl">
            <h2 className="text-6xl font-bold mb-8">
              <span className="text-purple-400">Stop</span> spending hours hunting for profits.
            </h2>
            <h2 className="text-6xl font-bold mb-12">
              Let <span className="text-purple-400">Profit Hunter</span> find them for you.
            </h2>
          </div>
        </section>

        {/* Pricing Section */}
        <section id="pricing" className="mt-16 py-16 bg-gray-900">
          <div className="container mx-auto px-4 max-w-7xl">
            <h2 className="text-4xl font-bold text-center mb-12 text-white">Choose Your Plan</h2>
            <div className="grid md:grid-cols-2 gap-8 max-w-4xl mx-auto">
              {/* Basic Plan */}
              <div className="bg-gray-800 p-8 rounded-lg shadow-lg border border-gray-700">
                <h3 className="text-2xl font-bold mb-4 text-white">Basic</h3>
                <p className="text-4xl font-bold mb-6 text-purple-400">
                  <span className="line-through text-gray-500">£49</span>{' '}
                  £40 <span className="text-xl font-normal text-gray-300">Launch Price</span>
                </p>
                <p className="text-xl text-gray-300 mt-2">/month</p>
                <ul className="mb-8 mt-4 text-gray-300">
                  <li className="flex items-center mb-2">
                    <Check className="h-5 w-5 text-green-400 mr-2" />
                    Access to A2A Database
                  </li>
                  <li className="flex items-center mb-2">
                    <Check className="h-5 w-5 text-green-400 mr-2" />
                    Custom Filter Notifications
                  </li>
                  <li className="flex items-center mb-2">
                    <Check className="h-5 w-5 text-green-400 mr-2" />
                    ASIN Price/Stock Monitor
                  </li>
                  <li className="flex items-center mb-2">
                    <Check className="h-5 w-5 text-green-400 mr-2" />
                    Priority Support
                  </li>
                </ul>
                <button className="w-full bg-purple-600 text-white py-2 px-4 rounded-md hover:bg-purple-700 transition duration-300">
                  Get Started
                </button>
              </div>
              {/* Pro Plan */}
              <div className="bg-purple-900 text-white p-8 rounded-lg shadow-lg">
                <h3 className="text-2xl font-bold mb-4">Pro</h3>
                <p className="text-4xl font-bold mb-6">
                  <span className="line-through text-gray-400">£69</span>{' '}
                  £59 <span className="text-xl font-normal text-gray-300">Launch Price</span>
                </p>
                <p className="text-xl text-gray-300 mt-2">/month</p>
                <ul className="mb-8 mt-4">
                  <li className="flex items-center mb-2">
                    <Check className="h-5 w-5 text-green-300 mr-2" />
                    All Basic Features
                  </li>
                  <li className="flex items-center mb-2">
                    <Check className="h-5 w-5 text-green-300 mr-2" />
                    Storefront Hunter - Monitor up to 10 stores
                  </li>
                  <li className="flex items-center mb-2">
                    <Check className="h-5 w-5 text-green-300 mr-2" />
                    Priority Support
                  </li>
                </ul>
                <button className="w-full bg-white text-purple-900 py-2 px-4 rounded-md hover:bg-gray-100 transition duration-300">
                  Get Started
                </button>
              </div>
            </div>
          </div>
        </section>

        {/* FAQ Section */}
        <section id="faq" className="py-16 bg-gray-700">
          <div className="container mx-auto px-4 max-w-7xl">
            <h2 className="text-2xl font-bold text-gray-200 mb-2">FAQ</h2>
            <h3 className="text-4xl font-bold text-white mb-8">Frequently Asked Questions</h3>
            <div className="space-y-4">
              {faqs.map((faq, index) => (
                <div key={index} className="border-b border-gray-600">
                  <button
                    className="flex justify-between items-center w-full py-4 text-left text-gray-200 hover:text-white focus:outline-none"
                    onClick={() => toggleQuestion(index)}
                  >
                    <span className="text-lg font-medium">{faq.question}</span>
                    {expandedIndex === index ? (
                      <Minus className="h-5 w-5 text-gray-400" />
                    ) : (
                      <Plus className="h-5 w-5 text-gray-400" />
                    )}
                  </button>
                  {expandedIndex === index && (
                    <div className="pb-4 pr-4">
                      <p className="text-gray-300">{faq.answer}</p>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </section>
      </main>
    </div>
  )
}
