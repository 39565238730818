import React, { useState } from 'react';
import { FaUserCircle, FaBell, FaGlobe, FaTelegram, FaDiscord } from 'react-icons/fa';

const Settings = ({ darkMode, toggleDarkMode }) => {
  const [telegramUsername, setTelegramUsername] = useState('');
  const [telegramStep, setTelegramStep] = useState('disconnected');
  const [verificationCode, setVerificationCode] = useState('');

  const initializeTelegramConnection = async (e) => {
    e.preventDefault();
    try {
      setVerificationCode('123456');
      setTelegramStep('verifying');
    } catch (error) {
      console.error('Failed to initialize Telegram connection:', error);
    }
  };

  const verifyTelegramConnection = async () => {
    try {
      setTelegramStep('connected');
    } catch (error) {
      console.error('Failed to verify Telegram connection:', error);
    }
  };

  const disconnectTelegram = async () => {
    try {
      setTelegramStep('disconnected');
      setTelegramUsername('');
    } catch (error) {
      console.error('Failed to disconnect Telegram:', error);
    }
  };

  return (
    <div className="p-6 max-w-4xl mx-auto space-y-6">
      <h1 className="text-2xl font-bold mb-6">Settings</h1>
      
      {/* Notification Settings */}
      <div className="bg-white rounded-lg shadow-md p-6">
        <div className="flex items-center gap-2 mb-6">
          <FaBell className="text-xl text-[#8957e5]" />
          <h2 className="text-xl font-semibold">Notification Settings</h2>
        </div>
        
        <div className="space-y-6">
          {/* Discord Integration */}
          <div className="border-t pt-6">
            <div className="flex items-center gap-2 mb-4">
              <FaDiscord className="text-xl text-[#8957e5]" />
              <h3 className="font-medium">Discord Notifications</h3>
            </div>
            <div className="flex items-center justify-between">
              <p className="text-sm text-gray-500">Connect your Discord account to receive instant price alerts</p>
              <button
                className="px-4 py-2 bg-[#8957e5] text-white rounded-md hover:bg-[#7548d5] transition-colors"
                onClick={() => window.open('YOUR_DISCORD_OAUTH_URL', '_blank')}
              >
                Connect Discord
              </button>
            </div>
          </div>

          {/* Telegram Integration */}
          <div className="border-t pt-6">
            <div className="flex items-center gap-2 mb-4">
              <FaTelegram className="text-xl text-[#8957e5]" />
              <h3 className="font-medium">Telegram Notifications</h3>
            </div>
            
            {telegramStep === 'disconnected' && (
              <form onSubmit={initializeTelegramConnection} className="space-y-4">
                <div>
                  <label className="block text-sm text-gray-500 dark:text-gray-400 mb-2">
                    Connect your Telegram account to receive instant price alerts
                  </label>
                  <div className="flex gap-2">
                    <input
                      type="text"
                      value={telegramUsername}
                      onChange={(e) => setTelegramUsername(e.target.value)}
                      placeholder="Your Telegram Username"
                      className="flex-1 px-3 py-2 bg-gray-100 text-gray-900 border-none rounded-md focus:outline-none focus:ring-2 focus:ring-[#8957e5]"
                    />
                    <button
                      type="submit"
                      className="px-4 py-2 bg-[#8957e5] text-white rounded-md hover:bg-[#7548d5] transition-colors"
                    >
                      Connect
                    </button>
                  </div>
                </div>
              </form>
            )}

            {telegramStep === 'verifying' && (
              <div className="space-y-4">
                <div className="bg-[#8957e5]/10 dark:bg-[#8957e5]/20 p-4 rounded-md">
                  <h4 className="font-medium text-[#8957e5] dark:text-[#8957e5]">Follow these steps:</h4>
                  <ol className="mt-2 text-sm text-[#8957e5]/80 dark:text-[#8957e5]/80 space-y-2">
                    <li>1. Open Telegram and search for @YourBotName</li>
                    <li>2. Start a conversation with the bot</li>
                    <li>3. Send this code: <span className="font-mono font-bold">{verificationCode}</span></li>
                  </ol>
                </div>
                <button
                  onClick={verifyTelegramConnection}
                  className="w-full py-2 px-4 bg-[#8957e5] text-white rounded-md hover:bg-[#7548d5] transition-colors"
                >
                  I've sent the code
                </button>
              </div>
            )}

            {telegramStep === 'connected' && (
              <div className="space-y-4">
                <div className="bg-green-50 dark:bg-green-900/30 p-4 rounded-md">
                  <p className="text-green-700 dark:text-green-200">
                    ✓ Telegram notifications are enabled
                  </p>
                </div>
                <button
                  onClick={disconnectTelegram}
                  className="w-full py-2 px-4 border border-red-600 text-red-600 rounded-md hover:bg-red-50 dark:hover:bg-red-900/20 transition-colors"
                >
                  Disconnect Telegram
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Appearance Settings */}
      <div className="bg-white rounded-lg shadow-md p-6">
        <div className="flex items-center gap-2 mb-6">
          <FaGlobe className="text-xl text-[#8957e5]" />
          <h2 className="text-xl font-semibold">Appearance</h2>
        </div>
        
        <div className="space-y-6">
          <div className="flex justify-between items-center">
            <div>
              <h3 className="font-medium">Dark Mode</h3>
              <p className="text-sm text-gray-500 dark:text-gray-400">Toggle dark mode on or off</p>
            </div>
            <label className="relative inline-flex items-center cursor-pointer">
              <input 
                type="checkbox" 
                checked={darkMode} 
                onChange={toggleDarkMode}
                className="sr-only peer" 
              />
              <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-[#8957e5]/30 dark:peer-focus:ring-[#8957e5]/30 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#8957e5]"></div>
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
